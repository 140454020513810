footer {
    position: fixed;
    bottom: 0;
    background-color: rgb(0, 212, 255);
    color: white;
    text-align: center;
    padding: 10px 5px;
    width: 100%;
    font-size: .9em;
    left: 0;
    a {
        color: white;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}