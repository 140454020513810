.result {
    text-align: left;
    padding: 0 30px;
    margin: 10px -20px;
    text-align: center;
}
.temp {
    font-size: 2em;
    display: flex;
    justify-content: center;
    gap: 1em;
}
.weathericon {
    font-size: 10em;
    padding-bottom: 0.1em;
}
.yellow {
    color: #ffae00;
}
.blue {
    color: #0069b4;
}
.fs-xl {
    font-size: 1.75em;
}
.dailyWeather {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-between;
    li{
        margin: 0;
        padding: 0;
        p:last-child {
            font-size: .8em;
        }
    }
}