.button {
    padding: 10px 20px;
    border-radius: 30px;
    background-color: rgb(0, 212, 255);
    color: white;
    border: solid 1px rgb(0, 212, 255);
    transition: all 500ms ease-in-out;
    margin-top:20px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;

    &:hover {
        color: rgb(0, 212, 255);
        background-color: white;
    }
}