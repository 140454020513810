.adress-form {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1.5em;
    label {
        display: flex;
        justify-content: space-between;
        width: 260px;
        align-items: center;
    }
    input {
        border: solid 1px rgb(0, 212, 255);
        border-radius: 5px 0 0 5px; 
        padding: 5px 10px;
        &:read-only {
            background-color: #efefef;
            color: #777;
        }
    }
    button {
        padding: 5px 10px;
        border-radius: 0 5px 5px 0;
        background-color: rgb(0, 212, 255);
        color: white;
        border:solid 1px rgb(0, 212, 255);
        transition: all 500ms ease-in-out;
        font-size: 1.2em;
        cursor: pointer;
        &:hover {
            color: rgb(0, 212, 255);
            background-color: white;
        }
    }
}
.alert {
    color: red;
    font-weight: 700;
    width: 100%;
    padding: 0;
    margin: 0;
}
.icons {
    font-size: 2em;
    display: flex;
    justify-content: center;
    gap:1em;
}