html {
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
}

body {
    margin: 0;
    padding: 30px;
    background: white;
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(0, 212, 255) 100%);
    min-height: calc(100vh - 60px);
}
h1 {
    color: white;
}

.main {
    max-width: 400px;
    margin: 0 auto;
    background-color: white;
    padding: 30px 20px;
    border-radius: 20px;
}